$(document).ready(function () {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function () {
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function (e) {
        var target = $(this).attr("target"),
            url = $(this).attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    }).click(function () {
        var target = $(this).find("a.js-click-item-link").attr("target"),
            url = $(this).find("a.js-click-item-link").attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                scrollTo(target);
                return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 60; // Distance from Browserbottom where the animation should start

    function fadeInElements() {
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function () {
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function () {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function () {
        if (location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        } else {
            $(this).attr('target', '_blank');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * nav
    // *
    // *
    $(".js-nav-button").click(function () {
        $(".js-nav-button, .js-nav").toggleClass("active");
        $(".js-header").toggleClass("nav-open");
        $(".js-nav-hide").toggleClass("hide");
    })

    $(window).scroll(function () {
        if ($(".js-nav").hasClass("active")) {
            $(".js-nav, .js-nav-button").removeClass("active");
            $(".js-header").removeClass("nav-open");
        }
    });

    $(".js-nav svg").click(function () {
        $(this).parents("li").toggleClass("open").find("ul").slideToggle();
        $(this).parents("li").siblings().removeClass("open").find("ul").slideUp();
    })

    $(".js-nav li.active").addClass("open").find("ul").slideDown();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * heroSlider
    // *
    // *
    var $heroSlider = $(".js-hero-slider");

    if ($heroSlider.length) {
        $heroSlider.each(function () {
            var wrapper = $(this).parents(".js-hero-slider-wrapper");

            $(this).slick({
                infinite: true,
                fade: false,
                dots: true,
                draggable: true,
                arrows: true,
                autoplay: true,
                autoplaySpeed: 4000,
                prevArrow: wrapper.find(".js-hero-slider-nav-prev"),
                nextArrow: wrapper.find(".js-hero-slider-nav-next")
            });

        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * contentSlider
    // *
    // *
    var $contentSlider = $(".js-content-slider");

    if ($contentSlider.length) {
        $contentSlider.each(function () {

            $(this).slick({
                infinite: true,
                fade: false,
                dots: true,
                draggable: true,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 4000,
            });

        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * carousel
    // *
    // *
    var $carousel = $(".js-carousel");

    if ($carousel.length) {
        $carousel.each(function () {

            $(this).slick({
                infinite: true,
                fade: false,
                dots: true,
                draggable: true,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 4000,
                variableWidth: true,
                adaptiveHeight: false,
                responsive: [
                    {
                        breakpoint: 800,
                        settings: {
                            variableWidth: false,
                            adaptiveHeight: true,
                        }
                    }
                ]
            });

        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * textToggle
    // *
    // *
    $(".js-text-toggle-button").click(function () {
        $(this).addClass("toggled").parent(".js-text-toggle-wrapper").find(".js-text-toggle-hidden").slideToggle();
    })



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * load more
    // *
    // *
    // var $wrapper = $(".js-loadmore-wrapper"),
    //     $pagination = $(".js-loadmore-pagination"),
    //     $button = $(".js-loadmore-button");

    // $button.click(function(){
    //     var url = $pagination.find("a.next").attr("href");
    //     var $nextLink = $pagination.find("a.next").removeClass("next").next("a").addClass("next");

    //     if (!$pagination.find("a.next").length) {
    //         $button.fadeOut();
    //     }

    //     $.get(url).done(function(data){
    //         $(data).find(".js-loadmore-wrapper .js-loadmore-item").appendTo($wrapper);
    //     });
    // });

    // // mark next navigation page for load more button on init
    // $pagination.find("a.active").next("a").addClass("next");

    // // hide button if not enough news on init
    // if ($pagination.find("a").length <= 1) {
    //     $button.hide();
    // }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * highlightNav
    // *
    // *
    var eventTitle = $(".js-event-title");
    var highlightLink = $(".js-highlightnav a");
    var pageTitle = $(".js-page-title");

    addClassForLongWords(eventTitle, 20)
    addClassForLongWords(highlightLink, 14)
    addClassForLongWords(pageTitle, 14)


    function addClassForLongWords(item, number) {
        item.each(function () {
            if ($(this).text().length > number) {
                $(this).addClass("long");
            }
        })
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * event fake popup
    // *
    // *
    $(".js-event-popup-button").click(function (e) {
        e.preventDefault();

        var link = $(this).attr("href");

        $(this).parents(".js-event-popup").addClass("popup");

        setTimeout(function () {
            window.location.href = link;
        }, 400);

    })



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * cursor standard
    // *
    // *
    $('.js-cursor-wrapper').on('mousemove', function (e) {
        var cursorHeight = $(this).find('.js-cursor').outerHeight() / 2;
        var cursorWidth = $(this).find('.js-cursor').outerWidth() / 2;
        $(this).find('.js-cursor').addClass("show").offset({ left: e.pageX - cursorWidth, top: e.pageY - cursorHeight });
    });

    $('.js-cursor-wrapper').on('mouseleave', function (e) {
        $(this).find('.js-cursor').removeClass("show");
    });


});



// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky Header
// *
// *
$(window).on("load scroll", function () {
    if ($(window).scrollTop() >= 40) {
        $(".js-header").addClass("sticky");
    } else {
        $(".js-header").removeClass("sticky");
    }
});
